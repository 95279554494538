import { css } from 'styled-components'
import { FONT_OPTIONS } from 'mgr/lib/utils/Constants'
import Button from '../../../../common/Button'
import ColorPicker from '../../../../common/ColorPicker'
import { Select, CheckBox, Input } from '../../../../common/FormElement'
import Switch from '../../../../common/Switch'
import * as styles from '../assets/styles'
import BackgroundImageUpload from '../containers/BackgroundImageUpload'
import Cropper from '../containers/Cropper'
import RadioList from './RadioList'

const _isValidWidgetSettings = errorsDict => {
  let flag = true
  // eslint-disable-next-line guard-for-in
  for (const key in errorsDict) {
    window.Interface._alert(errorsDict[key])
    flag = false
  }
  return flag
}

const _save = (actions, appState, widgetSettings, e) => {
  if (_isValidWidgetSettings(appState.errors)) {
    actions.tryPostWidgetSettings(widgetSettings)
  }
}

const _changeField = (actions, fieldName, e) => {
  if (['reservationHoldTimeMins', 'buttonText'].includes(fieldName) && e.target.value === '') {
    actions.updateField(fieldName, e.target.value, 'Error: empty text field')
  } else if (fieldName === 'font') {
    actions.updateField(fieldName, FONT_OPTIONS[e.target.value])
  } else {
    actions.updateField(fieldName, e.target.value)
  }
}

const _changeFieldWithValue = (actions, _widgetSettings, fieldName, val, e) => {
  actions.updateField(fieldName, val)

  // special case for changing themes
  if (fieldName === 'widgetTemplateTheme') {
    actions.updateTheme()
  }
  if (fieldName === 'tailoredCommunicationOn' && val) {
    actions.updateField('isTailoredCommunicationPolicyDefaultOn', true)
  }
}

const _changeCheckbox = (actions, fieldName, e) => {
  actions.updateField(fieldName, $(e.target).is(':checked'))
}

const _changeHexColor = (actions, fieldName, color) => {
  actions.updateField(fieldName, color.hex)
}

const _changeColor = (actions, fieldName, fieldOpacityName, color) => {
  actions.updateField(fieldName, color.hex)
  actions.updateField(fieldOpacityName, color.rgb.a)
}

const getScriptText = (widgetSettings, venue) =>
  `<script>\n\tSEVENROOMS_VENUE_ID =  "${venue}"\n` +
  `${widgetSettings.hideCalendar ? '\tHIDE_CALENDAR =  true\n' : ''}</script>` +
  `\n<script src="https://${window.location.hostname}/embed.js"></script>`

const getCopyCode = (widgetSettings, venue) => {
  const type = widgetSettings.buttonId % 2 === 1 ? 'dark' : 'light'
  // eslint-disable-next-line no-nested-ternary
  const size = widgetSettings.buttonId > 4 ? 'lg' : widgetSettings.buttonId > 2 ? 'md' : 'sm'

  const color = `sr-${widgetSettings.buttonColor}`
  const scriptText = getScriptText(widgetSettings, venue)

  return `<div id="sr-events-root" class="sr-${size} sr-${type} ${color}">\n\t'${widgetSettings.buttonText}\n</div>\n${scriptText}`
}

const getLinkText = venue => `${window.location.hostname}/events/${venue}`

const capitalizeFirstLetter = str => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()

const TextEntry = ({ name, label, widgetSettings, appState, actions, width = 150, marginLeft = 0 }) => {
  const _css = {
    width: `${width}px`,
    marginBottom: '5px',
  }

  const _value = widgetSettings[name]

  return (
    <div style={{ marginLeft: `${marginLeft}px` }}>
      <p className="group-label">{label}</p>
      <Input
        inputCss={_css}
        value={_value}
        onChangeHandler={_changeField.bind(this, actions, name)}
        disabled={appState.isGetting || appState.isPosting}
      />
      <div className="float-end" />
    </div>
  )
}

export const WidgetSettingsForm = ({ MEDIA_URL, widgetSettings, appState, actions, backgroundImage }) => {
  const lightThemeImg = `${MEDIA_URL}images/eventmanager/template_light_2x.png`
  const darkThemeImg = `${MEDIA_URL}images/eventmanager/template_dark_2x.png`

  const venue = window.location.pathname.split('/')[2]
  const iframeSrc = window.location.host.includes('sevenrooms')
    ? `/events/${venue}?width=320`
    : `https://www.sevenrooms.com/events/${venue}?width=320`

  return (
    <form style={styles.Form} className="styled" data-sr-validate>
      <div style={styles.PreviewPane}>
        <iframe title={`${venue} Widget`} id="widget-iframe" src={iframeSrc} style={styles.PreviewFrame} />
      </div>

      <div style={styles.FormSection}>
        <div style={styles.SubHeader}>General</div>

        <p style={styles.MiniSubHeader}>Color scheme</p>
        <p style={styles.Note}>Choose a template to start your booking widget</p>

        <div className="artificial-exclusion">
          <div style={styles.ThemeColumn}>
            <CheckBox
              name="widget_template_theme"
              labelText="Light"
              inputCss={styles.RadioCheckBox}
              onClickHandler={_changeFieldWithValue.bind(this, actions, widgetSettings, 'widgetTemplateTheme', 'LIGHT')}
              disabled={appState.isGetting || appState.isPosting}
              parentClass="radio-style"
              checked={widgetSettings.widgetTemplateTheme === 'LIGHT'}
            />
          </div>
          <div style={styles.ThemeColumn}>
            <CheckBox
              name="widget_template_theme"
              labelText="Dark"
              inputCss={styles.RadioCheckBox}
              onClickHandler={_changeFieldWithValue.bind(this, actions, widgetSettings, 'widgetTemplateTheme', 'DARK')}
              disabled={appState.isGetting || appState.isPosting}
              parentClass="radio-style"
              checked={widgetSettings.widgetTemplateTheme === 'DARK'}
            />
          </div>
        </div>
        <img
          src={widgetSettings.widgetTemplateTheme === 'LIGHT' ? lightThemeImg : darkThemeImg}
          style={styles.TemplateImage}
          alt="Theme Example"
        />
        <div className="float-end" />
        <Select
          name="font"
          labelText="Font"
          value={FONT_OPTIONS.indexOf(widgetSettings.font)}
          inputCss={styles.Select}
          disabled={appState.isGetting || appState.isPosting}
          options={FONT_OPTIONS}
          onChangeHandler={_changeField.bind(this, actions, 'font')}
        />
        <div className="float-end" />
        <p style={{ ...styles.MiniSubHeader, margin: '22px 0 8px' }}>Main Colors</p>
        <div style={styles.SettingRow}>
          <div style={styles.SettingColTitle}>
            <p className="group-label">Primary color</p>
          </div>
          <div style={styles.SettingCol}>
            <ColorPicker
              color={{ hex: widgetSettings.colorPrimary, opacity: 1.0 }}
              onChangeColor={_changeHexColor.bind(this, actions, 'colorPrimary')}
            />
          </div>
        </div>
        <div style={styles.SettingRow}>
          <div style={styles.SettingColTitle}>
            <p className="group-label">Page background</p>
          </div>
          <div style={styles.SettingCol}>
            <ColorPicker
              color={{
                hex: widgetSettings.colorBackground,
                opacity: widgetSettings.colorBackgroundOpacity,
              }}
              disableAlpha={false}
              onChangeColor={_changeColor.bind(this, actions, 'colorBackground', 'colorBackgroundOpacity')}
            />
          </div>
        </div>

        {window.globalInit.venueSettings.enable_3d_floorplan && (
          <>
            <div style={styles.SettingRow}>
              <div style={styles.SettingColTitle}>
                <p className="group-label">Floorplan Available Color</p>
              </div>
              <div style={styles.SettingCol}>
                <ColorPicker
                  color={{
                    hex: widgetSettings.floorplanAvailableColor,
                    opacity: widgetSettings.floorplanAvailableOpacity,
                  }}
                  disableAlpha={false}
                  onChangeColor={_changeColor.bind(this, actions, 'floorplanAvailableColor', 'floorplanAvailableOpacity')}
                />
              </div>
            </div>

            <div style={styles.SettingRow}>
              <div style={styles.SettingColTitle}>
                <p className="group-label">Floorplan Unavailable Color</p>
              </div>
              <div style={styles.SettingCol}>
                <ColorPicker
                  color={{
                    hex: widgetSettings.floorplanUnavailableColor,
                    opacity: widgetSettings.floorplanUnavailableOpacity,
                  }}
                  disableAlpha={false}
                  onChangeColor={_changeColor.bind(this, actions, 'floorplanUnavailableColor', 'floorplanUnavailableOpacity')}
                />
              </div>
            </div>
            {window.globalInit.venueSettings.enable_3d_floorplan && (
              <div style={styles.SettingRow}>
                <div style={styles.SettingColTitle}>
                  <p className="group-label">Floorplan Sold Out Color</p>
                </div>
                <div style={styles.SettingCol}>
                  <ColorPicker
                    color={{
                      hex: widgetSettings.floorplanSoldOutColor,
                      opacity: widgetSettings.floorplanSoldOutColorOpacity,
                    }}
                    disableAlpha={false}
                    onChangeColor={_changeColor.bind(this, actions, 'floorplanSoldOutColor', 'floorplanSoldOutColorOpacity')}
                  />
                </div>
              </div>
            )}
            <div style={styles.SettingRow}>
              <div style={styles.SettingColTitle}>
                <p className="group-label">Floorplan Click Color</p>
              </div>
              <div style={styles.SettingCol}>
                <ColorPicker
                  color={{
                    hex: widgetSettings.floorplanClickColor,
                    opacity: widgetSettings.floorplanClickOpacity,
                  }}
                  disableAlpha={false}
                  onChangeColor={_changeColor.bind(this, actions, 'floorplanClickColor', 'floorplanClickOpacity')}
                />
              </div>
            </div>

            <div style={styles.SettingRow}>
              <div style={styles.SettingColTitle}>
                <p className="group-label">Floorplan Hover Color</p>
              </div>
              <div style={styles.SettingCol}>
                <ColorPicker
                  color={{
                    hex: widgetSettings.floorplanHoverColor,
                    opacity: widgetSettings.floorplanHoverOpacity,
                  }}
                  disableAlpha={false}
                  onChangeColor={_changeColor.bind(this, actions, 'floorplanHoverColor', 'floorplanHoverOpacity')}
                />
              </div>
            </div>
          </>
        )}

        <BackgroundImageUpload url={widgetSettings.img_url} />
        {widgetSettings.isCropping ? <Cropper isCropping={widgetSettings.isCropping} /> : null}

        <div className="inline">
          <Button style={styles.SaveButton} label="Save" onClick={_save.bind(this, actions, appState, widgetSettings, backgroundImage)} />
        </div>
        <div className="float-end" />
        <div style={styles.BorderLine} />
      </div>

      <div style={styles.FormSection}>
        <div style={styles.SubHeader}>Widget button settings</div>
        <p className="group-label">Customize text on button</p>
        <Input
          name="custom_button_text"
          inputCss={appState.errors?.buttonText ? { ...styles.ErrorField, marginBottom: '5px' } : { marginBottom: '5px' }}
          value={widgetSettings.buttonText}
          onChangeHandler={_changeField.bind(this, actions, 'buttonText')}
          disabled={appState.isGetting || appState.isPosting}
        />
        {appState.errors?.buttonText ? <div style={{ clear: 'both', color: 'red' }}>Text field should not be empty</div> : null}
        <div className="float-end" />
        <p className="group-label">Characters</p>
        {widgetSettings.buttonText !== widgetSettings.buttonText.toUpperCase() ? (
          <div className="inline" style={{ marginBottom: '5px' }}>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
            <div
              style={styles.CharBox}
              onClick={_changeField.bind(this, actions, 'buttonText', {
                target: { value: widgetSettings.buttonText.toUpperCase() },
              })}
            >
              AA
            </div>
            <div style={{ ...styles.CharBox, ...styles.BlueCharBox }}>Aa</div>
          </div>
        ) : (
          <div className="inline" style={{ marginBottom: '5px' }}>
            <div style={{ ...styles.CharBox, ...styles.BlueCharBox }}>AA</div>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
            <div
              style={styles.CharBox}
              onClick={_changeField.bind(this, actions, 'buttonText', {
                target: {
                  value: capitalizeFirstLetter(widgetSettings.buttonText),
                },
              })}
            >
              Aa
            </div>
          </div>
        )}
        <div className="float-end" />
        <p className="group-label">Button color</p>
        <ColorPicker
          color={{ hex: widgetSettings.buttonColor, opacity: 1.0 }}
          onChangeColor={_changeHexColor.bind(this, actions, 'buttonColor')}
        />
        <div className="float-end" />
        <p className="group-label" style={{ margin: '13px 0 7px' }}>
          Select which button you would like to use on your website
        </p>
        <div style={styles.SettingRow}>
          <div style={styles.SettingCol}>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
            <div
              style={styles.SelectButtonStyle(1, widgetSettings.buttonId)}
              onClick={_changeFieldWithValue.bind(this, actions, widgetSettings, 'buttonId', 1)}
            >
              <div style={styles.ButtonStyle('small', 'dark', widgetSettings.buttonColor)}>
                <i style={styles.ButtonImg('small')} className="sr-fa sr-fa-logo" />
                <span style={{ ...styles.ButtonText, maxWidth: '87px' }}>{widgetSettings.buttonText}</span>
              </div>
            </div>
          </div>
          <div style={styles.SettingCol}>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
            <div
              style={styles.SelectButtonStyle(2, widgetSettings.buttonId)}
              onClick={_changeFieldWithValue.bind(this, actions, widgetSettings, 'buttonId', 2)}
            >
              <div style={styles.ButtonStyle('small', 'light', widgetSettings.buttonColor)}>
                <i style={styles.ButtonImg('small')} className="sr-fa sr-fa-logo" />
                <span style={{ ...styles.ButtonText, maxWidth: '87px' }}>{widgetSettings.buttonText}</span>
              </div>
            </div>
          </div>
        </div>
        <div style={styles.SettingRow}>
          <div style={styles.SettingCol}>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
            <div
              style={styles.SelectButtonStyle(3, widgetSettings.buttonId)}
              onClick={_changeFieldWithValue.bind(this, actions, widgetSettings, 'buttonId', 3)}
            >
              <div style={styles.ButtonStyle('medium', 'dark', widgetSettings.buttonColor)}>
                <i style={styles.ButtonImg('medium')} className="sr-fa sr-fa-logo" />
                <span style={{ ...styles.ButtonText, maxWidth: '100px' }}>{widgetSettings.buttonText}</span>
              </div>
            </div>
          </div>
          <div style={styles.SettingCol}>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
            <div
              style={styles.SelectButtonStyle(4, widgetSettings.buttonId)}
              onClick={_changeFieldWithValue.bind(this, actions, widgetSettings, 'buttonId', 4)}
            >
              <div style={styles.ButtonStyle('medium', 'light', widgetSettings.buttonColor)}>
                <i style={styles.ButtonImg('medium')} className="sr-fa sr-fa-logo" />
                <span style={{ ...styles.ButtonText, maxWidth: '100px' }}>{widgetSettings.buttonText}</span>
              </div>
            </div>
          </div>
        </div>
        <div style={styles.SettingRow}>
          <div style={styles.SettingCol}>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
            <div
              style={styles.SelectButtonStyle(5, widgetSettings.buttonId)}
              onClick={_changeFieldWithValue.bind(this, actions, widgetSettings, 'buttonId', 5)}
            >
              <div style={styles.ButtonStyle('large', 'dark', widgetSettings.buttonColor)}>
                <i style={styles.ButtonImg('large')} className="sr-fa sr-fa-logo" />
                <span style={{ ...styles.ButtonText, maxWidth: '130px' }}>{widgetSettings.buttonText}</span>
              </div>
            </div>
          </div>
          <div style={styles.SettingCol}>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
            <div
              style={styles.SelectButtonStyle(6, widgetSettings.buttonId)}
              onClick={_changeFieldWithValue.bind(this, actions, widgetSettings, 'buttonId', 6)}
            >
              <div style={styles.ButtonStyle('large', 'light', widgetSettings.buttonColor)}>
                <i style={styles.ButtonImg('large')} className="sr-fa sr-fa-logo" />
                <span style={{ ...styles.ButtonText, maxWidth: '130px' }}>{widgetSettings.buttonText}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="float-end" />
        <div className="inline">
          <div style={{ padding: '17px 0' }}>
            <CheckBox
              name="hide_calendar"
              labelText="Hide Calendar"
              inputCss={styles.RadioCheckBox}
              value="1"
              onClickHandler={_changeCheckbox.bind(this, actions, 'hideCalendar')}
              disabled={appState.isGetting || appState.isPosting}
              checked={widgetSettings.showCalendar}
            />
          </div>
        </div>
        <div className="inline">
          <p style={{ ...styles.Note, marginTop: '18px', marginBottom: '18px' }}>
            In order for the selected widget button settings to take effect,
            <br />
            you will need to be sure the snippet below matches the snippet on your website.
          </p>
        </div>
        <div style={{ ...styles.MiniSubHeader, margin: '22px 0 8px' }}>Links</div>
        <p className="group-label">Copy and paste this code into your website</p>
        <textarea style={{ width: '450px', height: '138px', resize: 'none' }} readOnly value={getCopyCode(widgetSettings, venue)} />
        <p className="group-label">Direct link to widget</p>
        <div className="inline">
          <textarea style={{ width: '450px', height: '17px', resize: 'none' }} readOnly value={getLinkText(venue)} />
        </div>
        <div className="float-end" />
        <div style={styles.BorderLine} />
      </div>

      <div style={styles.FormSection}>
        <div style={styles.SubHeader}>Advanced Settings</div>
        <div style={styles.SettingRow}>
          <div style={styles.SettingCol}>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
            <div
              onClick={_changeFieldWithValue.bind(this, actions, widgetSettings, 'widgetTemplateTheme', widgetSettings.widgetTemplateTheme)}
              style={styles.RestoreDefaults}
            >
              Restore defaults
            </div>
          </div>
          <div style={{ ...styles.SettingColTitle, width: '400px', paddingTop: '4px' }}>
            <p style={styles.Note}>This will restore all advanced settings to the template selected above</p>
          </div>
        </div>

        <div style={styles.MiniSubHeader}>Fonts</div>
        <div>
          <div style={styles.SettingRow}>
            <div style={styles.SettingColTitle}>
              <p className="group-label">Primary</p>
            </div>
            <div style={styles.SettingCol}>
              <ColorPicker
                color={{ hex: widgetSettings.fontsColorPrimary, opacity: 1.0 }}
                onChangeColor={_changeHexColor.bind(this, actions, 'fontsColorPrimary')}
              />
            </div>
          </div>

          <div style={styles.SettingRow}>
            <div style={styles.SettingColTitle}>
              <p className="group-label">Secondary</p>
            </div>
            <div style={styles.SettingCol}>
              <ColorPicker
                color={{
                  hex: widgetSettings.fontsColorSecondary,
                  opacity: 1.0,
                }}
                onChangeColor={_changeHexColor.bind(this, actions, 'fontsColorSecondary')}
              />
            </div>
          </div>

          <div style={styles.SettingRow}>
            <div style={styles.SettingColTitle}>
              <p className="group-label">Button</p>
            </div>
            <div style={styles.SettingCol}>
              <ColorPicker
                color={{
                  hex: widgetSettings.fontsColorButton,
                  opacity: 1.0,
                }}
                onChangeColor={_changeHexColor.bind(this, actions, 'fontsColorButton')}
              />
            </div>
          </div>

          <div style={styles.SettingRow}>
            <div style={styles.SettingColTitle}>
              <p className="group-label">Links</p>
            </div>
            <div style={styles.SettingCol}>
              <ColorPicker
                color={{ hex: widgetSettings.fontsColorLinks, opacity: 1.0 }}
                onChangeColor={_changeHexColor.bind(this, actions, 'fontsColorLinks')}
              />
            </div>
          </div>

          <div style={styles.SettingRow}>
            <div style={styles.SettingColTitle}>
              <p className="group-label">Checkout inactive</p>
            </div>
            <div style={styles.SettingCol}>
              <ColorPicker
                color={{
                  hex: widgetSettings.fontsColorCheckoutInactive,
                  opacity: 1.0,
                }}
                onChangeColor={_changeHexColor.bind(this, actions, 'fontsColorCheckoutInactive')}
              />
            </div>
          </div>

          <div style={styles.SettingRow}>
            <div style={styles.SettingColTitle}>
              <p className="group-label">Checkout active</p>
            </div>
            <div style={styles.SettingCol}>
              <ColorPicker
                color={{
                  hex: widgetSettings.fontsColorCheckoutActive,
                  opacity: 1.0,
                }}
                onChangeColor={_changeHexColor.bind(this, actions, 'fontsColorCheckoutActive')}
              />
            </div>
          </div>
        </div>
        <div style={styles.MiniSubHeader}>Styles</div>
        <div>
          <div style={styles.SettingRow}>
            <div style={styles.SettingColTitle}>
              <p className="group-label">Widget background</p>
            </div>
            <div style={styles.SettingCol}>
              <ColorPicker
                color={{
                  hex: widgetSettings.colorWidgetBackground,
                  opacity: widgetSettings.colorWidgetBackgroundOpacity,
                }}
                disableAlpha={false}
                onChangeColor={_changeColor.bind(this, actions, 'colorWidgetBackground', 'colorWidgetBackgroundOpacity')}
              />
            </div>
          </div>

          <div style={styles.SettingRow}>
            <div style={styles.SettingColTitle}>
              <p className="group-label">Cell background</p>
            </div>
            <div style={styles.SettingCol}>
              <ColorPicker
                color={{
                  hex: widgetSettings.colorCellBackground,
                  opacity: widgetSettings.colorCellBackgroundOpacity,
                }}
                disableAlpha={false}
                onChangeColor={_changeColor.bind(this, actions, 'colorCellBackground', 'colorCellBackgroundOpacity')}
              />
            </div>
          </div>

          <div style={styles.SettingRow}>
            <div style={styles.SettingColTitle}>
              <p className="group-label">Checkout Cell background</p>
            </div>
            <div style={styles.SettingCol}>
              <ColorPicker
                color={{
                  hex: widgetSettings.colorCheckoutBackground,
                  opacity: widgetSettings.colorCheckoutBackgroundOpacity,
                }}
                disableAlpha={false}
                onChangeColor={_changeColor.bind(this, actions, 'colorCheckoutBackground', 'colorCheckoutBackgroundOpacity')}
              />
            </div>
          </div>

          <div style={styles.SettingRow}>
            <div style={styles.SettingColTitle}>
              <p className="group-label">Lines</p>
            </div>
            <div style={styles.SettingCol}>
              <ColorPicker
                color={{
                  hex: widgetSettings.colorLines,
                  opacity: widgetSettings.colorLinesOpacity,
                }}
                disableAlpha={false}
                onChangeColor={_changeColor.bind(this, actions, 'colorLines', 'colorLinesOpacity')}
              />
            </div>
          </div>

          <div style={styles.SettingRow}>
            <div style={styles.SettingColTitle}>
              <p className="group-label">Hover outline</p>
            </div>
            <div style={styles.SettingCol}>
              <ColorPicker
                color={{
                  hex: widgetSettings.colorHoverOutline,
                  opacity: widgetSettings.colorHoverOutlineOpacity,
                }}
                disableAlpha={false}
                onChangeColor={_changeColor.bind(this, actions, 'colorHoverOutline', 'colorHoverOutlineOpacity')}
              />
            </div>
          </div>

          <div style={styles.SettingRow}>
            <div style={styles.SettingColTitle}>
              <p className="group-label">Error</p>
            </div>
            <div style={styles.SettingCol}>
              <ColorPicker
                color={{ hex: widgetSettings.fontsColorError, opacity: 1.0 }}
                onChangeColor={_changeHexColor.bind(this, actions, 'fontsColorError')}
              />
            </div>
          </div>
        </div>

        {/*  Hold times on events widget are coming in 2020!
        <div style={styles.MiniSubHeader}>Other</div>

        <div style={styles.SettingRow}>
          <div style={styles.SettingColTitle}>
            <p className="group-label">Minutes to complete reservation</p>
          </div>
          <div style={styles.SettingCol}>
            {appState.errors['reservationHoldTimeMins'] ? (
              <div style={styles.ErrorMessage}>
                Text field should not be empty
              </div>
            ) : null}
            <Input
              name="reservation_hold_time_mins"
              type="number"
              inputCss={
                appState.errors['reservationHoldTimeMins']
                  ? Object.assign({}, styles.SmallInput, styles.ErrorField)
                  : styles.SmallInput
              }
              value={widgetSettings.reservationHoldTimeMins}
              min={0}
              onChangeHandler={_changeField.bind(
                this,
                actions,
                'reservationHoldTimeMins'
              )}
              validator_type="int_positive"
              validator_message="Enter a number"
              disabled={appState.isGetting || appState.isPosting}
            />
          </div>
        </div>
        */}
        <div className="float-end" />
        <div className="inline">
          <Button style={styles.SaveButton} label="Save" onClick={_save.bind(this, actions, appState, widgetSettings)} />
        </div>
        <div className="float-end" />
        <div style={styles.BorderLine} />
      </div>

      <div style={styles.FormSection}>
        <div style={styles.SubHeader}>Request form configurations</div>
        <div style={styles.MiniSubHeader}>Party size selector</div>
        <div style={styles.Block} className="artificial-exclusion">
          <CheckBox
            name="party_size_type"
            labelText="Total"
            inputCss={styles.RadioCheckBox}
            onClickHandler={_changeFieldWithValue.bind(this, actions, widgetSettings, 'partySizeType', 'TOTAL')}
            disabled={appState.isGetting || appState.isPosting}
            parentClass="radio-style"
            checked={widgetSettings.partySizeType === 'TOTAL'}
          />
          <CheckBox
            name="party_size_type"
            labelText="Male / Female"
            inputCss={styles.RadioCheckBox}
            onClickHandler={_changeFieldWithValue.bind(this, actions, widgetSettings, 'partySizeType', 'MALE_FEMALE')}
            disabled={appState.isGetting || appState.isPosting}
            parentClass="radio-style"
            checked={widgetSettings.partySizeType === 'MALE_FEMALE'}
          />
          <div className="float-end" />
        </div>
        <div style={styles.MiniSubHeader}>Optional Fields</div>
        <div style={styles.Block}>
          <CheckBox
            name="enable_field_time"
            labelText="Time"
            inputCss={styles.RadioCheckBox}
            onClickHandler={_changeCheckbox.bind(this, actions, 'enableFieldTime')}
            disabled={appState.isGetting || appState.isPosting}
            checked={widgetSettings.enableFieldTime}
          />
          <CheckBox
            name="enable_field_additional_info"
            labelText="Additional Information"
            inputCss={styles.RadioCheckBox}
            onClickHandler={_changeCheckbox.bind(this, actions, 'enableFieldAdditionalInfo')}
            disabled={appState.isGetting || appState.isPosting}
            checked={widgetSettings.enableFieldAdditionalInfo}
          />
        </div>
        <div className="inline">
          <Button style={styles.SaveButton} label="Save" onClick={_save.bind(this, actions, appState, widgetSettings)} />
        </div>
        <div className="float-end" />
        <div style={styles.BorderLine} />
      </div>

      <div style={styles.FormSection}>
        <div style={styles.SubHeader}>Configurations</div>
        <div style={styles.MiniSubHeader}>reCAPTCHA</div>
        <div style={styles.Block} className="settings-height">
          <CheckBox
            name="enableRecaptcha"
            inputCss={styles.RadioCheckBox}
            labelText="Enable"
            onClickHandler={_changeFieldWithValue.bind(this, actions, widgetSettings, 'recaptchaOn', !widgetSettings.recaptchaOn)}
            value={!widgetSettings.recaptchaOn}
            checked={widgetSettings.recaptchaOn}
          />
        </div>

        <RadioList
          selectedValue={widgetSettings.birthdayType}
          onClick={_changeFieldWithValue.bind(this, actions, widgetSettings, 'birthdayType')}
          listLabel="Birthday Input"
          options={[
            { value: 'Hidden', label: 'Hidden' },
            { value: 'Required', label: 'Required' },
            { value: 'Optional', label: 'Optional' },
          ]}
          customRadioListLabel={css`
            color: black;
            font-weight: 400;
            font-size: 14px;
            font-family: Roboto, sans-serif;
            margin-bottom: 10px;
          `}
          customRadioIcon={css`
            width: 20px;
            height: 20px;
          `}
          customRadioLabel={css`
            font-weight: 400;
            line-height: 20px;
            color: #aaa;
            font-size: 12px;
            padding: 0 0 0 4px;
          `}
          customRadioWrapper={css`
            margin-bottom: 4px;
          `}
          customRadioCheck={css`
            width: 10px;
            height: 10px;
            margin: 4px;
            background-color: #347baf;
          `}
        />

        <div className="inline">
          <Button style={styles.SaveButton} label="Save" onClick={_save.bind(this, actions, appState, widgetSettings)} />
        </div>
        <div className="float-end" />
        <div style={styles.BorderLine} />
      </div>
      <div style={styles.FormSection}>
        <div style={styles.SubHeader}>Customization</div>
        {[
          {
            name: 'textCancellationPolicy',
            label: 'I agree to the cancellation policy',
            width: '300',
          },
          {
            name: 'textVenueSmsMarketingOptIn',
            label: 'Text Marketing Opt-in: Venue',
            width: '300',
          },
          {
            name: 'textVenueSpecificMarketingOptIn',
            label: 'Email Marketing Opt-in: Venue',
            width: '300',
          },
          {
            name: 'textVenueGroupMarketingOptIn',
            label: 'Email Marketing Opt-in: Group',
            width: '300',
          },
          {
            name: 'textReservationSmsOptIn',
            label: 'Opt into SMS about reservations',
            width: '300',
          },
          {
            name: 'textCustomPrivacyPolicyLink',
            label: 'Custom Privacy Policy Link',
            width: '300',
          },
          {
            name: 'textCustomPrivacyPolicyLinkLabel',
            label: 'Privacy Policy (if Custom Privacy Policy Link provided)',
            width: '300',
            marginLeft: '16',
          },
          {
            name: 'textCustomPolicyHolderName',
            label: 'Policy Holder Name (if Custom Privacy Policy Link provided)',
            width: '300',
            marginLeft: '16',
          },
          {
            name: 'textCustomGdprPolicyLinkLabel',
            label: 'GDPR Policy',
            width: '300',
          },
          {
            name: 'textCustomGdprPolicyLink',
            label: 'Custom GDPR Policy Link',
            width: '300',
          },
          {
            name: 'textMinimumSpend',
            label: 'Minimum Spend',
            width: '300',
          },
          {
            name: 'textServiceFee',
            label: 'Service Fee',
            width: '300',
          },
        ].map((item, index) => {
          if (item.name === 'textReservationSmsOptIn' && !widgetSettings.remindersSmsEnabled) {
            return null
          }
          if (item.name === 'textVenueSmsMarketingOptIn' && !widgetSettings.isSmsMarketingEnabled) {
            return null
          }
          if (item.header) {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <div key={`h_${index}`} style={{ ...styles.MiniSubHeader, paddingTop: 20 }}>
                {item.header}
              </div>
            )
          }
          return (
            <TextEntry
              name={item.name}
              label={item.label}
              key={item.name}
              width={item.width}
              marginLeft={item.marginLeft}
              widgetSettings={widgetSettings}
              appState={appState}
              actions={actions}
            />
          )
        })}

        <div className="settings-height">
          <div className="settings-col settings-height">
            <span className="switch-label">Display additional fees in price</span>
          </div>
          <div className="settings-col settings-height">
            <Switch
              name="hide_event"
              toggle={_changeFieldWithValue.bind(
                this,
                actions,
                widgetSettings,
                'isFeesInPriceDisplayed',
                !widgetSettings.isFeesInPriceDisplayed
              )}
              value={!widgetSettings.isFeesInPriceDisplayed}
              style={{ margin: '7px 0' }}
            />
          </div>
        </div>

        <div className="settings-height">
          <div className="settings-col settings-height">
            <span className="switch-label">Mandate age consent during checkout</span>
          </div>
          <div className="settings-col settings-height" style={{ width: '100px' }}>
            <Switch
              name="hide_event"
              toggle={_changeFieldWithValue.bind(this, actions, widgetSettings, 'aboveAgeConsentOn', !widgetSettings.aboveAgeConsentOn)}
              value={!widgetSettings.aboveAgeConsentOn}
              style={{ margin: '7px 0' }}
            />
          </div>
          <div className="settings-col settings-height">
            <Input
              id="age-to-consent"
              type="number"
              inputCss={{ width: 30 }}
              value={widgetSettings.ageToConsent}
              min={1}
              onChangeHandler={_changeField.bind(this, actions, 'ageToConsent')}
              disabled={!widgetSettings.aboveAgeConsentOn}
              validator_type="int_positive"
              validator_message="Enter a number"
            />
          </div>
        </div>

        {widgetSettings.isSmsMarketingEnabled && (
          <div className="settings-height">
            <div className="settings-col settings-height">
              <span className="switch-label">Show Text Marketing Opt-in for Venue</span>
            </div>
            <div className="settings-col settings-height">
              <Switch
                name="hide_event"
                toggle={_changeFieldWithValue.bind(
                  this,
                  actions,
                  widgetSettings,
                  'venueSmsMarketingOn',
                  !widgetSettings.venueSmsMarketingOn
                )}
                value={!widgetSettings.venueSmsMarketingOn}
                style={{ margin: '7px 0' }}
              />
            </div>
          </div>
        )}

        {widgetSettings.isSmsMarketingEnabled && widgetSettings.venueSmsMarketingOn && (
          <div className="settings-height">
            <div className="settings-col inset">
              <CheckBox
                name="venue_specific_sms_marketing_policy_default"
                labelText="Opt-in is checked by default"
                inputCss={styles.RadioCheckBox}
                onClickHandler={_changeFieldWithValue.bind(
                  this,
                  actions,
                  widgetSettings,
                  'isVenueSmsMarketingPolicyDefaultOn',
                  !widgetSettings.isVenueSmsMarketingPolicyDefaultOn
                )}
                disabled={appState.isGetting || appState.isPosting}
                checked={widgetSettings.isVenueSmsMarketingPolicyDefaultOn}
              />
            </div>
          </div>
        )}

        <div className="settings-height">
          <div className="settings-col settings-height">
            <span className="switch-label">Show Email Marketing Opt-in for Venue</span>
          </div>
          <div className="settings-col settings-height">
            <Switch
              name="hide_event"
              toggle={_changeFieldWithValue.bind(
                this,
                actions,
                widgetSettings,
                'venueSpecificMarketingOn',
                !widgetSettings.venueSpecificMarketingOn
              )}
              value={!widgetSettings.venueSpecificMarketingOn}
              style={{ margin: '7px 0' }}
            />
          </div>
        </div>

        {widgetSettings.venueSpecificMarketingOn && (
          <div className="settings-height">
            <div className="settings-col inset">
              <CheckBox
                name="venue_specific_marketing_policy_default"
                labelText="Opt-in is checked by default"
                inputCss={styles.RadioCheckBox}
                onClickHandler={_changeFieldWithValue.bind(
                  this,
                  actions,
                  widgetSettings,
                  'isVenueSpecificMarketingPolicyDefaultOn',
                  !widgetSettings.isVenueSpecificMarketingPolicyDefaultOn
                )}
                disabled={appState.isGetting || appState.isPosting}
                checked={widgetSettings.isVenueSpecificMarketingPolicyDefaultOn}
              />
            </div>
          </div>
        )}

        <div className="settings-height">
          <div className="settings-col settings-height">
            <span className="switch-label">Show Email Marketing Opt-in for Group</span>
          </div>
          <div className="settings-col settings-height">
            <Switch
              name="hide_event"
              toggle={_changeFieldWithValue.bind(
                this,
                actions,
                widgetSettings,
                'venueGroupMarketingOn',
                !widgetSettings.venueGroupMarketingOn
              )}
              value={!widgetSettings.venueGroupMarketingOn}
              style={{ margin: '7px 0' }}
            />
          </div>
        </div>

        {widgetSettings.venueGroupMarketingOn && (
          <div className="settings-height">
            <div className="settings-col inset">
              <CheckBox
                name="venue_group_marketing_policy_default"
                labelText="Opt-in is checked by default"
                inputCss={styles.RadioCheckBox}
                onClickHandler={_changeFieldWithValue.bind(
                  this,
                  actions,
                  widgetSettings,
                  'isVenueGroupMarketingPolicyDefaultOn',
                  !widgetSettings.isVenueGroupMarketingPolicyDefaultOn
                )}
                disabled={appState.isGetting || appState.isPosting}
                checked={widgetSettings.isVenueGroupMarketingPolicyDefaultOn}
              />
            </div>
          </div>
        )}

        <div className="settings-height">
          <div className="settings-col settings-height">
            <span className="switch-label">Opt-in to receive tailored communications</span>
          </div>
          <div className="settings-col settings-height">
            <Switch
              name="hide_event"
              toggle={_changeFieldWithValue.bind(
                this,
                actions,
                widgetSettings,
                'tailoredCommunicationOn',
                !widgetSettings.tailoredCommunicationOn
              )}
              value={!widgetSettings.tailoredCommunicationOn}
              style={{ margin: '7px 0' }}
              disabled={widgetSettings.isTailoredCommunicationPolicyDisabled}
            />
          </div>
        </div>

        {widgetSettings.tailoredCommunicationOn && (
          <div className="settings-height">
            <div className="settings-col inset">
              <CheckBox
                name="tailored_communication_policy_default"
                labelText="Opt-in is checked by default"
                inputCss={styles.RadioCheckBox}
                onClickHandler={_changeFieldWithValue.bind(
                  this,
                  actions,
                  widgetSettings,
                  'isTailoredCommunicationPolicyDefaultOn',
                  !widgetSettings.isTailoredCommunicationPolicyDefaultOn
                )}
                disabled={appState.isGetting || appState.isPosting}
                checked={widgetSettings.isTailoredCommunicationPolicyDefaultOn}
              />
            </div>
          </div>
        )}

        <div className="settings-height">
          <div className="settings-col settings-height">
            <span className="switch-label">Show special attention message</span>
          </div>
          <div className="settings-col settings-height">
            <Switch
              name="hide_event"
              toggle={_changeFieldWithValue.bind(
                this,
                actions,
                widgetSettings,
                'showSpecialAttentionMessage',
                !widgetSettings.showSpecialAttentionMessage
              )}
              value={!widgetSettings.showSpecialAttentionMessage}
              style={{ margin: '7px 0' }}
            />
          </div>
        </div>

        {(widgetSettings.remindersSmsEnabled || widgetSettings.smsBookingNotificationEnabled) &&
          widgetSettings.showSmsOptInSettingsForTwilioUs && (
            <>
              <div className="settings-height">
                <div className="settings-col settings-height">
                  <span className="switch-label">Show opt-in for receiving SMS about reservations</span>
                </div>
                <div className="settings-col settings-height">
                  <Switch disabled name="hide_event" value={!widgetSettings.displayReservationSmsOptIn} style={{ margin: '7px 0' }} />
                </div>
              </div>
              {widgetSettings.displayReservationSmsOptIn && (
                <div className="settings-height">
                  <div className="settings-col settings-height">
                    <span className="switch-label sms_opt_in_policy_default" style={{ marginLeft: '15px' }}>
                      Checked by Default
                    </span>
                  </div>
                  <div className="settings-col settings-height">
                    <Switch
                      name="hide_event"
                      toggle={_changeFieldWithValue.bind(
                        this,
                        actions,
                        widgetSettings,
                        'reservationSmsOptInOn',
                        !widgetSettings.reservationSmsOptInOn
                      )}
                      value={!widgetSettings.reservationSmsOptInOn}
                      style={{ margin: '7px 0' }}
                    />
                  </div>
                </div>
              )}
            </>
          )}
        <div className="settings-height">
          <div className="settings-col settings-height">
            <span className="switch-label">Enable social media login</span>
          </div>
          <div className="settings-col settings-height">
            <Switch
              name="hide_event"
              toggle={_changeFieldWithValue.bind(
                this,
                actions,
                widgetSettings,
                'enableSocialMediaLogin',
                !widgetSettings.enableSocialMediaLogin
              )}
              value={!widgetSettings.enableSocialMediaLogin}
              style={{ margin: '7px 0' }}
            />
          </div>
        </div>
        <div className="settings-height">
          <div className="settings-col settings-height">
            <span className="switch-label">Ask for Dietary Restrictions on checkout page</span>
          </div>
          <div className="settings-col settings-height">
            <Switch
              name="hide_event"
              toggle={_changeFieldWithValue.bind(
                this,
                actions,
                widgetSettings,
                'enableDietaryRestrictions',
                !widgetSettings.enableDietaryRestrictions
              )}
              value={!widgetSettings.enableDietaryRestrictions}
              style={{ margin: '7px 0' }}
            />
          </div>
        </div>
        <div className="settings-height">
          <div className="settings-col settings-height">
            <span className="switch-label">Ask if Special Occasion on checkout page</span>
          </div>
          <div className="settings-col settings-height">
            <Switch
              name="hide_event"
              toggle={_changeFieldWithValue.bind(
                this,
                actions,
                widgetSettings,
                'enableSpecialOccasions',
                !widgetSettings.enableSpecialOccasions
              )}
              value={!widgetSettings.enableSpecialOccasions}
              style={{ margin: '7px 0' }}
            />
          </div>
        </div>
        <div className="settings-height">
          <div className="settings-col settings-height">
            <span className="switch-label">Display Map</span>
          </div>
          <div className="settings-col settings-height">
            <Switch
              name="hide_event"
              toggle={_changeFieldWithValue.bind(this, actions, widgetSettings, 'displayMap', !widgetSettings.displayMap)}
              value={!widgetSettings.displayMap}
              style={{ margin: '7px 0' }}
            />
          </div>
        </div>
        <div>
          <RadioList
            selectedValue={widgetSettings.salutationType}
            onClick={_changeFieldWithValue.bind(this, actions, widgetSettings, 'salutationType')}
            listLabel="Salutation Input"
            options={[
              { value: 'Hidden', label: 'Hidden' },
              { value: 'Required', label: 'Required' },
              { value: 'Optional', label: 'Optional' },
            ]}
            customRadioListLabel={css`
              color: black;
              font-weight: 400;
              font-size: 14px;
              font-family: Roboto, sans-serif;
              margin-bottom: 10px;
            `}
            customRadioIcon={css`
              width: 20px;
              height: 20px;
            `}
            customRadioLabel={css`
              font-weight: 400;
              line-height: 20px;
              color: #aaa;
              font-size: 12px;
              padding: 0 0 0 4px;
            `}
            customRadioWrapper={css`
              margin-bottom: 4px;
            `}
            customRadioCheck={css`
              width: 10px;
              height: 10px;
              margin: 4px;
              background-color: #347baf;
            `}
          />
        </div>
        <div className="inline">
          <Button style={styles.SaveButton} label="Save" onClick={_save.bind(this, actions, appState, widgetSettings, backgroundImage)} />
        </div>
        <div className="float-end" />
        <div style={styles.BorderLine} />
      </div>
    </form>
  )
}
