import _ from 'lodash'
import * as React from 'react'
import styled from 'styled-components'
import RadioLine from './RadioLine'

const RadioList = props => (
  <RadioListWrapper customRadioListWrapper={props.customRadioListWrapper}>
    {props.listLabel && <RadioListLabel customRadioListLabel={props.customRadioListLabel}>{props.listLabel}</RadioListLabel>}
    {_.map(props.options, option => {
      const isSelected = props.selectedValue === option.value
      return (
        <RadioLine
          key={`sr-key-radio-button-${option.value}`}
          value={option.value}
          label={option.label}
          onClick={props.onClick}
          isSelected={isSelected}
          customRadioWrapper={props.customRadioWrapper}
          customRadioIcon={props.customRadioIcon}
          customRadioCheck={props.customRadioCheck}
          customRadioLabel={props.customRadioLabel}
        >
          {isSelected && option.child}
        </RadioLine>
      )
    })}
  </RadioListWrapper>
)

RadioList.defaultProps = {
  selectedValue: undefined,
  listLabel: undefined,
  customRadioListWrapper: [],
  customRadioListLabel: [],
  customRadioWrapper: [],
  customRadioIcon: [],
  customRadioCheck: [],
  customRadioLabel: [],
}

const RadioListWrapper = styled.div`
  ${props => props.customRadioListWrapper};
`

const RadioListLabel = styled.span`
  display: inline-block;
  font-family: Roboto;
  ${props => props.customRadioListLabel};
`

export default RadioList
