import _ from 'lodash'
import React, { Component } from 'react'
import ReactCrop from 'react-image-crop'
import { connect } from 'react-redux'
import Button from '../../../../common/Button'
import { cropImage, closeCrop } from '../actions/services'
import * as styles from '../assets/styles'

class Cropper extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const noteStyle = _.assign({ margin: '5px 10px 10px' }, styles.eg)
    const message =
      'For your banner image, we recommend uploading a ' +
      'rectangular image that is no smaller than 1400 pixels wide and 700 ' +
      'pixels tall. Note your image must be below 4mb.'
    const cropObj = this.props.cropData || {
      x: 25,
      y: 25,
      width: 50,
      aspect: 2,
    }

    if (!this.props.url) {
      return null
    }

    return (
      <div id="crop-interface">
        <div style={styles.shadow} />
        <div style={styles.cropWrap}>
          <div id="content-header" style={styles.headerStyle}>
            <h2 style={styles.headerTextStyle}>Background Image</h2>
          </div>
          <div onClick={this.props.closeCrop} style={styles.closer}>
            &times;
          </div>
          <ReactCrop src={this.props.url} crop={cropObj} onComplete={this.props.cropImage} />
          <div>
            <p style={noteStyle}>{message}</p>
          </div>
          <div style={{ clear: 'both', paddingTop: 10 }}>
            <Button label="Save" onClick={this.props.closeCrop} style={{ width: 320, margin: '5px 10px' }} />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  url: state.widgetSettings.img_url,
  cropData: state.widgetSettings.cropData,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  cropImage: coords => {
    dispatch(cropImage(coords))
  },
  closeCrop: () => {
    dispatch(closeCrop())
  },
})

Cropper = connect(mapStateToProps, mapDispatchToProps)(Cropper)

export default Cropper
