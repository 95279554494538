import * as React from 'react'
import styled from 'styled-components'
import RadioSymbol from './RadioSymbol'

class RadioLine extends React.Component {
  static defaultProps = {
    isSelected: false,
    children: undefined,
    customRadioWrapper: [],
    customRadioIcon: [],
    customRadioCheck: [],
    customRadioLabel: [],
  }

  clickHandler = this.props.onClick.bind(this, this.props.value)

  render() {
    return (
      <RadioWrapper customRadioWrapper={this.props.customRadioWrapper}>
        <RadioSymbol
          onClick={this.clickHandler}
          isSelected={this.props.isSelected}
          customRadioIcon={this.props.customRadioIcon}
          customRadioCheck={this.props.customRadioCheck}
        />
        <RadioLabel value={this.props.value} onClick={this.clickHandler} customRadioLabel={this.props.customRadioLabel}>
          {this.props.label}
        </RadioLabel>
        {this.props.children}
      </RadioWrapper>
    )
  }
}

const RadioWrapper = styled.div`
  padding: 5px 0 5px 24px;
  height: 26px;
  position: relative;
  box-sizing: border-box;
  ${props => props.customRadioWrapper};
`

const RadioLabel = styled.label`
  font-size: 15px;
  line-height: 16px;
  font-family: Roboto;
  display: inline-block;
  cursor: pointer;
  box-sizing: inherit;
  ${props => props.customRadioLabel};
`

export default RadioLine
