import _ from 'lodash'
import React, { Component } from 'react'
import Dropzone from 'react-dropzone'
import { connect } from 'react-redux'
import { uploadPhoto, cropOpen, cropDelete } from '../actions/services'
import * as styles from '../assets/styles.js'

class BackgroundImageUpload extends Component {
  constructor(props) {
    super(props)
    this.onDrop = this.onDrop.bind(this)
  }

  onDrop(accepted) {
    this.props.uploadPhoto(accepted[0])
  }

  render() {
    const actionEdit = _.assign({}, styles.action, {
      backgroundImage: `url(${MEDIA_URL}images/eventmanager/edit.png)`,
    })

    const actionDelete = _.assign({}, styles.action, {
      backgroundImage: `url(${MEDIA_URL}images/eventmanager/delete.png)`,
    })

    const largeImageStyle = _.assign({}, styles.hasPhoto, styles.bigPhoto)

    let crop = {}
    if (!_.isEmpty(this.props.cropData)) {
      const widthDiff = Number(this.props.cropData.width) / 100
      const heightDiff = Number(this.props.cropData.height) / 100

      const width = `${100 / widthDiff}%`
      const top = `${-1 * (Number(this.props.cropData.y) / heightDiff)}%`
      const left = `${-1 * (Number(this.props.cropData.x) / widthDiff)}%`

      crop = { width, top, left }
    }

    const cropStyle = _.assign({}, styles.photoStyle, crop)
    const emptyPhotoStyle = _.assign({}, styles.emptyPhoto, styles.bigPhoto)

    const content = this.props.url ? (
      <div style={largeImageStyle}>
        <img src={this.props.url} style={cropStyle} />
        <div style={styles.photoActions}>
          <p onClick={this.props.cropOpen} style={$.extend({}, actionEdit, styles.actionAdjust)} />
          <p onClick={this.props.cropDelete} style={$.extend({}, actionDelete, styles.actionAdjust)} />
        </div>
      </div>
    ) : (
      <div id="drop-interface">
        <Dropzone style={emptyPhotoStyle} onDrop={this.onDrop} multiple={false} />
      </div>
    )

    return (
      <div id="banner-upload" style={styles.bgImageUpload}>
        <p style={{ ...styles.MiniSubHeader, margin: '22px 0 8px' }}>Background Image</p>
        {content}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  url: state.widgetSettings.img_url,
  cropData: state.widgetSettings.cropData,
})

const mapDispatchToProps = dispatch => ({
  uploadPhoto: file => {
    dispatch(uploadPhoto(file))
  },
  cropDelete: () => {
    dispatch(cropDelete())
  },
  cropOpen: () => {
    dispatch(cropOpen())
  },
})

BackgroundImageUpload = connect(mapStateToProps, mapDispatchToProps)(BackgroundImageUpload)

export default BackgroundImageUpload
