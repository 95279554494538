import * as React from 'react'
import styled from 'styled-components'

const RadioSymbol = props => (
  <RadioIcon onClick={props.onClick} customRadioIcon={props.customRadioIcon}>
    {props.isSelected && <RadioCheck customRadioCheck={props.customRadioCheck} />}
  </RadioIcon>
)

RadioSymbol.defaultProps = {
  isSelected: false,
  customRadioIcon: [],
  customRadioCheck: [],
}

const RadioIcon = styled.div`
  width: 16px;
  height: 16px;
  display: inline-block;
  position: absolute;
  left: 0;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(204, 204, 204, 1);
  border-radius: 50%;
  cursor: pointer;
  box-sizing: border-box;
  ${props => props.customRadioIcon};
`

const RadioCheck = styled.div`
  margin: 3px;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: rgba(118, 154, 242, 1);
  box-sizing: inherit;
  ${props => props.customRadioCheck};
`

export default RadioSymbol
