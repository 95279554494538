export const UPDATE_FIELD = 'UPDATE_FIELD'
export const UPDATE_THEME = 'UPDATE_THEME'

export const GETTING_WIDGET_SETTINGS = 'GETTING_WIDGET_SETTINGS'
export const GET_SUCCESS_WIDGET_SETTINGS = 'GET_SUCCESS_WIDGET_SETTINGS'
export const GET_FAIL_WIDGET_SETTINGS = 'GET_FAIL_WIDGET_SETTINGS'

export const POSTING_WIDGET_SETTINGS = 'POSTING_WIDGET_SETTINGS'
export const POST_SUCCESS_WIDGET_SETTINGS = 'POST_SUCCESS_WIDGET_SETTINGS'
export const POST_FAIL_WIDGET_SETTINGS = 'POST_FAIL_WIDGET_SETTINGS'

export const UPLOAD_PHOTO_PREVIEW = 'UPLOAD_PHOTO_PREVIEW'
export const UPLOAD_PHOTO_SUCCESS = 'UPLOAD_PHOTO_SUCCESS'

export const CROP_OPEN = 'CROP_OPEN'
export const CROP_CLOSE = 'CROP_CLOSE'
export const CROP_PHOTO = 'CROP_PHOTO'
export const CROP_DELETE = 'CROP_DELETE'
