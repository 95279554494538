import { Component } from 'react'
import { connect } from 'react-redux'
import { tryGetWidgetSettings, tryPostWidgetSettings, updateField, updateTheme } from '../actions/services'
import * as styles from '../assets/styles'
import { WidgetSettingsForm } from '../components/widgetsettings'
import { VenuePrivilegeLevelEnum } from '@sevenrooms/core/domain'
import { Banner, Root } from '@sevenrooms/core/ui-kit/layout'
import { Icon } from '@sevenrooms/core/ui-kit/icons'

class App extends Component {
  constructor(props) {
    super(props)
    props.actions.tryGetWidgetSettings(props.venueId)
  }

  render() {
    const showBanner =
      [VenuePrivilegeLevelEnum.MANAGER, VenuePrivilegeLevelEnum.SUPERUSER].includes(window.globalInit.userDomain.venue_privilege_level) &&
      !window.globalInit.venueSettings.payments_enabled
    const bannerContent = showBanner && (
      <Root theme="vx">
        <Banner
          canDismiss={false}
          type="attention"
          title="Get Paid Through Your Events Widget"
          description="Connect a processor in minutes to enable online payments."
          icon={<Icon name="VMSWeb-creditcard" size="2x" />}
          action="Connect"
          onAction={() => window.open(`/manager2/${window.globalInit.venueUrlKey}/settings/payment-integration/view`, '_blank')}
        />
      </Root>
    )
    return (
      <div>
        <div id="content-header">
          <h2>Events Widget Settings</h2>
        </div>
        {bannerContent}
        <div style={styles.Page}>
          <WidgetSettingsForm
            MEDIA_URL={this.props.MEDIA_URL}
            widgetSettings={this.props.widgetSettings}
            appState={this.props.appState}
            actions={this.props.actions}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  widgetSettings: state.widgetSettings,
  appState: state.appState,
})

const mapDispatchToProps = dispatch => ({
  actions: {
    updateField: (fieldName, val, error) => {
      dispatch(updateField(fieldName, val, error))
    },
    updateTheme: () => {
      dispatch(updateTheme())
    },
    tryGetWidgetSettings: venueId => {
      dispatch(tryGetWidgetSettings(venueId))
    },
    tryPostWidgetSettings: widgetSettings => {
      dispatch(tryPostWidgetSettings(widgetSettings))
    },
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(App)
